import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { reviewPaymentResponses } from "../../utils/data/responseCodeMessages";
import states from "../../utils/data/states.json";
import actions from "./actions";
import initialState from "./initialState";

const { REACT_APP_API_URL } = process.env;

export const postPayment = createAsyncThunk(
  "POST: /payment/v2",
  async (payload, { rejectWithValue }) => {
    const { token } = payload;
    const header = { headers: { Authorization: token } };
    const config = payload?.brandDetails;

    delete payload.token;
    delete payload.brandDetails;

    const res = await axios
      .post(`${REACT_APP_API_URL}/payment/v2`, payload, header)
      .then((response) => {
        if (response?.data?.tracking) {
          return response.data;
        }
        const { PendingTransactions } = response.data;
        PendingTransactions.forEach(val => payload.known_pending_transaction_numbers.push(val.TrackingNumber));
        return response.data;
      })
      .catch((error) => {
        console.log("error", error?.response);
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = reviewPaymentResponses(config)[data.message]
          || reviewPaymentResponses(config)[status]
          || reviewPaymentResponses(config)["default"];
        return rejectWithValue(errorResponse);
      });

    return res;
  }
);

export const getPaymentHistory = createAsyncThunk(
  "GET: /paymentHistory",
  async (token, { rejectWithValue }) => {
    const header = { headers: { Authorization: token } };
    const res = await axios
      .get(`${REACT_APP_API_URL}/payment?sort=created_on:desc`, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data,
          isFailed: true
        });
      });
    return res;
  }
);


export const getIdempotencyToken = createAsyncThunk(
  "GET: /payment/token",
  async (authToken, { rejectWithValue }) => {
    const header = { headers: { Authorization: authToken } };
    const res = await axios
      .get(`${REACT_APP_API_URL}/payment/token`, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data
        });
      });
    return res;
  }
);

export const getConvenienceFeeMicroservice = createAsyncThunk(
  "GET: /{accountId}/fee",
  async (payload, { rejectWithValue }) => {
    const header = { headers: { Authorization: payload.authToken } };
    payload.state_of_residence = states.find(state => state.longName === payload.state_of_residence)?.name;
    payload.transaction_amount = Number(payload.transaction_amount);
    delete payload.authToken;

    const res = await axios
      .post(`${REACT_APP_API_URL}/payment/fee`, payload, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data
        });
      });
    return res;
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: actions,
  extraReducers: {
    [getConvenienceFeeMicroservice.pending]: (state) => {
      state.fee.isLoading = true;
      state.fee.response = null;
      state.fee.error = null;
    },
    [getConvenienceFeeMicroservice.fulfilled]: (state, action) => {
      state.fee.isLoading = false;
      state.fee.response = action.payload;
      state.fee.error = null;
    },
    [getConvenienceFeeMicroservice.rejected]: (state, action) => {
      state.fee.isLoading = false;
      state.fee.response = null;
      state.fee.error = action.payload;
    },
    [postPayment.pending]: (state) => {
      state.error = false;
    },
    [postPayment.fulfilled]: (state, action) => {
      state.PaymentPostedResponse = action;
      state.isSubmitting = false;
    },
    [postPayment.rejected]: (state, action) => {
      state.error = action?.payload || action;
      state.isSubmitting = false;
    },
    [getPaymentHistory.pending]: (state) => {
      state.history.isLoading = true;
      state.history.response = null;
      state.history.error = null;
    },
    [getPaymentHistory.fulfilled]: (state, action) => {
      state.history.isLoading = false;
      state.history.response = action.payload;
    },
    [getPaymentHistory.rejected]: (state, action) => {
      state.history.isLoading = false;
      state.history.error = action.payload || true;
    },
    [getIdempotencyToken.pending]: (state) => {
      state.idempotency.isSubmitting = true;
      state.error = false;
    },
    [getIdempotencyToken.fulfilled]: (state, action) => {
      state.idempotency.isSubmitting = false;
      state.idempotency.token = action.payload.requestToken;
    },
    [getIdempotencyToken.rejected]: (state, action) => {
      state.idempotency.isSubmitting = false;
      state.idempotency.error = action.payload;
      // request the uuid from the server to use, but we can create a local uuid as a fall back to still allow the user
      // to submit a payment. Using a local uuid is attached uniquely per payment session. This is different from BLP.
      state.idempotency.token = uuidv4();
      state.isSubmitting = false;
    }
  }
});

export const {
  setIsPaymentSuccessful,
  setLoan,
  setName,
  setCardNumbers,
  setCardBrand,
  setExpirationDate,
  setSecurityCode,
  setPaymentAmount,
  setStreet,
  setState,
  setCity,
  setZipCode,
  setConvFee,
  setPendingTransactions,
  setTotal,
  setConfirmationNum,
  setSubmitting,
  clearIdempotency,
  setPaymentType,
  setPaymentDetails,
  setIsAchVerificationComplete,
  setIsFinicityFlow,
  setFormErrorMessage,
  setWarningMessage,
  setLoanPaymentType,
  setAutoPayDetails,
  clearPaymentState,
  clearPaymentDetails,
  clearCardForm,
  clearLoanState,
  setManuallyLinkFlow,
  setPaymentDate,
  setStateOfResidence,
  resetAchVerificationState,
  clearPaymentForm,
  setIsStateOfResidenceConfirmed
} = paymentSlice.actions;

export default paymentSlice.reducer;
