import React, { useEffect, useRef, useState, useMemo, forwardRef, useImperativeHandle } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Grid, InputAdornment, SvgIcon, TextField, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AutoCompleteDropdown, Typography } from "../../../../components";
import { Checkbox, Button } from "../../../../components/mui";
import { ButtonElement } from "../../../../services/sdk/components/ButtonElement.js";
import { setActivity } from "../../../../store/activity/activity.slice";
import {
  setPaymentType,
  setFormErrorMessage,
  setIsAchVerificationComplete,
  setIsStateOfResidenceConfirmed
} from "../../../../store/payment/makePayment.slice";
import { clearExpressEnhancedAccount } from "../../../../store/savedAccounts/savedAccounts.slice";
import { REGISTER_NEW_USER, REGISTERED } from "../../../../utils/constants/constants";
import states from "../../../../utils/data/states.json";
import { handlePaymentAmount, validateAmount, formatAmount } from "../../../../utils/helpers/cardHelpers";
import { useMultipleFormErrors } from "../../../../utils/hooks/useMultipleFormErrors";
import FinicityDisplay from "./finicity/FinicityDisplay";
import FinicityService from "./finicity/FinicityService";
import ManualLinkDisplay from "./ManualLinkDisplay";
import AchModals from "./modals";
import RegisteredDisplay from "./registeredDisplay/index";

const AchForm = (props, ref) => {
  const {
    finicityRef,
    submitForm,
    formTraits: {
      convFee,
      shouldDisableAmount,
      ach: { maxAchAmount, minAchAmount }
    },
    loan: { next_amount_due }
  } = props;

  // * Named Selectors
  const paymentState = (state) => state.payment;
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const achVerificationState = (state) => state.achVerification;
  const savedAccountsState = (state) => state.savedAccounts;

  // * Redux State
  const { form, isFinicityFlow, paymentDetails, isAchVerificationComplete, paymentType } = useSelector(paymentState);
  const { authToken, user, flow, stateOfResidence: authStateOfResidence } = useSelector(authState);
  const { config, details } = useSelector(institutionState);
  const { verify, sign } = useSelector(achVerificationState);
  const { getAccounts, expressEnhancedAccount } = useSelector(savedAccountsState);
  const results = useMemo(() => getAccounts?.response?.results || [], [getAccounts?.response?.results]);

  // * Functions available to parent
  useImperativeHandle(ref, () => ({
    startFinicityFlow: () => finicityRef.current.startFinicityFlow(),
    closeFinicityFlow: () => finicityRef.current.closeFinicityFlow()
  }));

  // * Form Hook Declarations
  const options = {
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldFocusError: true,
    defaultValues: {
      amount: form?.amount || next_amount_due,
      account: paymentDetails?.account,
      nameOnAccount: user.account_name || paymentDetails?.nameOnAccount,
      date: paymentDetails?.date || null,
      routingNumber: paymentDetails?.routingNumber,
      accountNumber: paymentDetails?.accountNumber,
      accountType: paymentDetails?.accountType,
      type: paymentDetails?.type,
      payFromSavedAccount: paymentDetails?.payFromSavedAccount || "",
      isFinicityFlow,
      isRegisteredPath: flow === REGISTERED
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    control,
    setValue,
    getValues,
    trigger
  } = useForm(options);

  // * Hooks
  const { errorMessage } = useMultipleFormErrors(errors, ["stateOfResidenceInput"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formAmount = useWatch({ control, name: "amount" });
  const previousAmount = useRef(next_amount_due);
  const finicityContainerRef = useRef();

  // * Local State
  const [stateAnnouncement, setStateAnnouncement] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowError, setShouldShowError] = useState(false);

  // * watch values
  const stateOfRes = authStateOfResidence?.hasStateOfResidence ? user?.state_of_residence : paymentDetails?.stateOfResidenceAchInput;
  const watchStateOFResidenceAchDropdown = watch("stateOfResidenceAchDropdown", stateOfRes || "");
  const watchStateOfResidenceAchInput = watch("stateOfResidenceAchInput", stateOfRes || "");
  const watchAchCheckbox = authStateOfResidence?.hasStateOfResidence ? watch("achCheckbox", false) : null;
  const payFromSavedAccount = watch("payFromSavedAccount");
  const watchNameOnAccount = watch("nameOnAccount");

  const iFrameDisplay = finicityContainerRef?.current?.style?.display;

  // * Listeners
  useEffect(() => {
    dispatch(setPaymentType("ach"));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setIsStateOfResidenceConfirmed(watchAchCheckbox));
  }, [watchAchCheckbox, dispatch]);

  useEffect(() => {
    if (!iFrameDisplay || iFrameDisplay.toLowerCase() !== "none") {
      setIsButtonDisabled(false);
    }
  }, [iFrameDisplay]);

  useEffect(() => {
    if (paymentType || errorMessage) {
      dispatch(setFormErrorMessage(errorMessage));
    }
  }, [dispatch, paymentType, errorMessage]);

  useEffect(() => {
    if (expressEnhancedAccount) {
      const { last4, banking_details } = expressEnhancedAccount;
      const accountDescription = `Account ending in ${last4} with ${banking_details?.name_of_bank}`;
      setValue("account", accountDescription);
      setValue("accountNumber", banking_details?.account_number);
      setValue("routingNumber", banking_details?.routing_number);
      setValue("accountType", banking_details?.type);
    }
    return () => dispatch(clearExpressEnhancedAccount());
  }, [expressEnhancedAccount]);

  const setModalClosed = (route) => {
    setIsModalOpen(false);
    setIsButtonDisabled(false);
    if (route) {
      navigate(route);
    }
  };

  const amountOptions = {
    onChange: (e) => {
      handlePaymentAmount(e.target.value, previousAmount, convFee, setError, clearErrors, dispatch, setValue);
    },
    required: "Payment amount is required",
    validate: {
      validAmount: value => validateAmount(value, maxAchAmount, minAchAmount)
    },
    onBlur: e => {
      formatAmount(e.target.value, convFee, dispatch, setValue);
      trigger("amount");
    }
  };

  const handleActivity = () => dispatch(setActivity());

  const isValidState = (value) => {
    const findState = states.find((state) => state?.longName?.toLowerCase() === value?.toLowerCase());
    return (!value || !findState) ? "Invalid value" : null;
  };

  useEffect(() => {
    if (!payFromSavedAccount) return undefined;

    const currentLoan = results?.find(loan => loan.id === payFromSavedAccount);
    const loanNameOnAccount = currentLoan?.banking_details?.name_on_account;

    setValue("nameOnAccount", loanNameOnAccount, {
      shouldValidate: true,
      shouldDirty: true
    });
  }, [payFromSavedAccount, results, setValue]);

  return (
    <>
      <aside style={{ margin: "1rem 0" }}>
        {
          details?.services?.sdk?.enhanced
            ? <ButtonElement />
            :
            <>
              <div id="swbc-consumer-experience" tabIndex="-1" ref={finicityContainerRef} />
              <FinicityService
                ref={finicityRef}
                user={user}
                nameOnAccount={getValues("nameOnAccount")}
                token={authToken.value}
                details={details}
                setValue={setValue}
                setIsButtonDisabled={setIsButtonDisabled}
                setIsModalOpen={setIsModalOpen}
                setShouldShowError={setShouldShowError}
                flow={flow}
                entry="payment"
                achWebIframe={finicityContainerRef}
              />
              <AchModals
                shouldShowError={shouldShowError}
                isModalOpen={isModalOpen}
                setModalClosed={setModalClosed}
                config={config}
                entry="payment"
              />
              <Button
                color="secondary"
                disabled={isButtonDisabled || sign.isLoading || verify.isSubmitting}
                loading={isButtonDisabled || sign.isLoading || verify.isSubmitting}
                onClick={() => {
                  dispatch(setIsAchVerificationComplete(false));
                  dispatch(setActivity());
                  finicityRef.current.openFinicityFlow();
                }}
                sx={{ margin: "1rem 0" }}
              >
                CONNECT YOUR BANK
              </Button>
            </>
        }
        <br />

        <form onSubmit={handleSubmit(submitForm)} onBlur={handleActivity} id="payment-form" noValidate={true}>
          <Grid container
            direction="column"
            columns={{ xs: 12, md: 12 }}
            spacing={1}
            component="fieldset"
            sx={{ border: "none", padding: "0px" }}
          >
            {
              (flow === REGISTERED || flow === REGISTER_NEW_USER) &&
              (
                <Grid item xs={12} container spacing={1}>
                  <RegisteredDisplay
                    control={control}
                    config={config}
                    register={register}
                    errors={errors}
                    maxPostDays={details?.max_post_days}
                    setError={setError}
                    nameOnAccount={watchNameOnAccount}
                    setValue={setValue}
                  />
                </Grid>
              )
            }

            {
              flow !== REGISTERED && (
                (isFinicityFlow)
                  ?
                  <FinicityDisplay
                    register={register}
                    errors={errors}
                    getValues={getValues}
                    isAchVerificationComplete={isAchVerificationComplete}
                    isFinicityFlow={isAchVerificationComplete}
                  />
                  : <ManualLinkDisplay
                    nameOnAccount={user.account_name}
                    register={register}
                    errors={errors}
                    setValue={setValue}
                  />

              )
            }
            <Grid item xs={12}>
              <TextField
                {...register("amount", amountOptions)}
                autoComplete=" transaction-amount"
                disabled={shouldDisableAmount}
                error={!!errors?.amount}
                fullWidth
                helperText={errors?.amount ? errors.amount.message : " "}
                id="payment-amount-input"
                inputProps={{ "aria-required": "true" }}
                InputProps={{ startAdornment: <InputAdornment position="start"><SvgIcon component={AttachMoneyIcon}></SvgIcon></InputAdornment> }}
                label="Payment Amount"
                placeholder={Number(formAmount) === 0 ? "0.00" : ""}
                required
                sx={{ zIndex: 0 }}
              />
            </Grid>
            {
              config.services?.payments?.state_of_residence?.enabled
                ?
                (
                  <>
                    <Grid item xs={12}>
                      <AutoCompleteDropdown
                        registerAutoComplete={{
                          ...register("stateOfResidenceAchDropdown", {
                            required: "State of residence is required",
                            validate: {
                              state: (value) => isValidState(value)
                            }
                          })
                        }}
                        registerTextField={{
                          ...register("stateOfResidenceAchInput", {
                            required: "State of residence is required",
                            validate: {
                              state: (value) => isValidState(value)
                            }
                          })
                        }}
                        aria-label="Please select a state"
                        errors={errors?.stateOfResidenceAchDropdown || errors?.stateOfResidenceAchInput}
                        options={states?.map(ele => ele.longName)}
                        getOptionLabel={(option) => option}
                        label="State of Residence"
                        id="state-of-residence"
                        selected={watchStateOFResidenceAchDropdown.length ? watchStateOFResidenceAchDropdown : ""}
                        onInputChange={(_, value) => {
                          setValue("stateOfResidenceAchInput", value, {
                            shouldDirty: true,
                            shouldValidate: true
                          });
                        }}
                        onChange={(_, value) => {
                          setStateAnnouncement(value);
                          setValue("stateOfResidenceAchDropdown", value, {
                            shouldDirty: true,
                            shouldValidate: true
                          });
                        }}
                        inputValue={watchStateOfResidenceAchInput ? watchStateOfResidenceAchInput : ""}
                        value={watchStateOFResidenceAchDropdown ? watchStateOFResidenceAchDropdown : null}
                        isOptionEqualToValue={(option, value) => (value === "" || value === option)}
                        stateAnnouncement={stateAnnouncement}
                      />
                    </Grid>
                    {
                      authStateOfResidence.hasStateOfResidence ?
                        <Grid item>
                          <FormControl error={!!errors?.achCheckbox}>
                            <FormControlLabel
                              {...register("achCheckbox", {
                                required: "Please confirm state of residence."
                              })}
                              aria-label="Click check mark box to confirm your state of residence."
                              control={<Checkbox checked={watchAchCheckbox || false} />}
                              label={<Typography> I confirm my current state of residence is {watchStateOFResidenceAchDropdown}</Typography>}
                            />
                            <FormHelperText>{errors?.achCheckbox?.message || " "}</FormHelperText>
                          </FormControl>
                        </Grid>
                        : null
                    }
                  </>
                )
                :
                null
            }

          </Grid>
        </form>
      </aside>
    </>
  );
};
export default forwardRef(AchForm);
