// Allowed Actions
export const allowedActions = {
  ACH_PAYMENT: "ach_payment",
  AUTHORIZE_AUTOPAY: "authorize_autopay",
  BUTTON_CLICK: "button_click",
  CARD_PAYMENT: "card_payment",
  DELETE_AUTOPAY: "delete_autopay",
  LOGIN_EXPRESS_PAY: "login_express_pay",
  LOGIN_REGISTERED: "login_registered",
  LOGOUT: "logout",
  REGISTRATION: "registration",
  CONNECT_ACH_ACCOUNT: "connect_ach_account",
  REGISTRATION_PROMOTION: "registration_promotion",
  UPDATE_AUTOPAY: "update_autopay"
};

// Allowed Categories
export const allowedCategories = {
  ACH_PAYMENT_SELECT: "ach_payment_select",
  ATTEMPT_ABANDONED: "attempt_abandoned",
  ATTEMPT_FAILURE: "attempt_failure",
  ACH_ACCOUNT: "ach_account",
  ATTEMPT_SUCCESS: "attempt_success",
  BREADCRUMBS_BACK: "breadcrumbs_back",
  BREADCRUMBS_FORWARD: "breadcrumbs_forward",
  CANCEL_AUTOPAY: "cancel_autopay",
  CARD_PAYMENT_SELECT: "card_payment_select",
  EXPRESS_PAY: "express_pay",
  MANAGE_AUTOPAY: "manage_autopay",
  PAY_NOW: "pay_now",
  REGISTERED: "registered",
  SETUP_AUTOPAY: "setup_autopay",
  UNKNOWN: "unknown"
};
