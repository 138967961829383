import React, { useState, createContext } from "react";

export const SwivelContext = createContext({
  isLoading: false,
  previousRoute: "",
  setIsLoading: () => {},
  setPreviousRoute: () => {}
});

export const SwivelContextProvider = ({ children }) => {
  const [ isLoading, setIsLoading ] = useState(false);
  const [ previousRoute, setPreviousRoute ] = useState("");
  return (
    <SwivelContext.Provider value={{
      isLoading,
      previousRoute,
      setIsLoading,
      setPreviousRoute
    }}>
      {children}
    </SwivelContext.Provider>
  );
};
