import { useState } from "react";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { allowedActions, allowedCategories } from "../constants/constants";

export const useGoogleAnalytics = () => {
  const institutionState = (state) => state.institution;
  const { config } = useSelector(institutionState);
  const [ actions ] = useState(allowedActions);
  const [ categories ] = useState(allowedCategories);

  // Checks if a user has enabled their browser's do not track features.
  const hasDoNotTrack = navigator.doNotTrack === "1" || window.doNotTrack === "1";

  /**
   * @description Initializes Google Analytics.
   */

  const initialize = () => {
    if (!hasDoNotTrack) {
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_WEB_DATA_STREAM,
          gaOptions: {
            cookieFlags: "max-age=7200;samesite=none;secure",
            titleCase: false
          }
        }
      ]);
    }
  };

  /**
   * @description Allows a developer to send in a predefined event to be sent for google analytics 4.
   *
   * @param {string} category - A predefined category for a GA4 event
   * @param {string} action - A predefined action for a GA4 event
   * @param {Object} data - Data object that contains additional attributes based on react-ga4 implementation
   */

  const trackEvent = (action, category, data = {}) => {
    let isValid = true;

    // Validate parameters are in the allowed lists
    if (!Object.values(actions).includes(action)) {
      console.warn(`[GA4]: Found ${action} with type "${typeof action}"`);
      isValid = false;
    }

    if (!Object.values(categories).includes(category)) {
      console.warn(`[GA4]: Found ${category} with type "${typeof category}"`);
      isValid = false;
    }

    if (typeof data !== "object" || Array.isArray(data)) {
      console.warn(`[GA4]: data is not an object! type: ${typeof data}`);
      isValid = false;
    }

    if (!hasDoNotTrack && ReactGA._hasLoadedGA && isValid) {
      // Create an event object.
      const event = {
        category,
        action,
        label: data?.label ? data?.label : config.id,
        ...data
      };

      // Send the event to Google Analytics.
      ReactGA.event(event);
    }
  };

  /**
   * @description submits page_view event
   */

  const pageView = () => {
    if (!hasDoNotTrack && ReactGA._hasLoadedGA) {
      ReactGA.send({ hitType: "pageview" });
    }
  };

  return {
    initialize,
    trackEvent,
    pageView,
    actions,
    categories
  };
};
