import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { EXPRESS_PAY, REGISTERED } from "../../utils/constants/constants";
import {
  userChangeEmail,
  userChangePassword,
  deeplinkLogin,
  putStateOfResidenceResponses,
  postGuest,
  promoteApiResponses,
  postUniqueIdLookupResponses
} from "../../utils/data/responseCodeMessages";
import { removeStatusCode } from "../../utils/helpers/commonHelpers";
import actions from "./actions";
import initialState from "./initialState";

const { REACT_APP_API_URL } = process.env;
const roleTypes = {
  "full-user": REGISTERED,
  "guest": EXPRESS_PAY
};

export const postTokenRefresh = createAsyncThunk(
  "POST: /refresh",
  async (token, { rejectWithValue }) => {
    const header = { headers: { Authorization: token } };
    const res = await axios
      .post(`${REACT_APP_API_URL}/refresh`, null, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data
        });
      });
    return res;
  }
);

export const postLogin = createAsyncThunk(
  "POST: /login",
  async (form, { rejectWithValue }) => {
    const payload = {
      email_address: form.emailAddress.toLowerCase().trim(),
      password: form.password.trim(),
      institution_id: form.institutionId
    };

    const res = await axios
      .post(`${REACT_APP_API_URL}/login`, payload)
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue({
          status: error.response.status,
          data: error.response.data
        })
      );
    return res;
  }
);

export const postExpressPayAuth = createAsyncThunk(
  "POST: /guest",
  async (form, { rejectWithValue }) => {
    const response = await axios
      .post(`${REACT_APP_API_URL}/guest`, form)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = postGuest()[status]
          || postGuest()[data.errorMessage]
          || postGuest()["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const postRegister = createAsyncThunk(
  "POST: /register",
  async (form, { rejectWithValue }) => {
    const response = await axios
      .post(`${REACT_APP_API_URL}/guest`, form)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = postGuest()[status]
          || postGuest()[data.errorMessage]
          || postGuest()["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const postNonValidationRegister = () => createAsyncThunk(
  "POST: /user/provisional",
  async (form, { rejectWithValue }) => {
    const response = await axios
      .post(`${REACT_APP_API_URL}/user/provisional`, form)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data
        });
      });
    return response;
  }
);

export const putChangeEmail = createAsyncThunk(
  "PUT: /user/{user_id}/email_address",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/user/${payload?.user_id}/email_address`;
    const header = { headers: { Authorization: payload?.token } };

    delete payload?.token;
    delete payload?.user_id;

    const response = await axios
      .put(url, payload, header)
      .then((res) => {
        const successResponse = userChangeEmail()[res.status];
        return { ...successResponse, ...res.data };
      })
      .catch((error) => {
        const status = error?.response?.status || (error?.request?.status || 504);
        const data = error?.response?.data;
        const defaultResponse = {
          severity: "error",
          response: data
        };
        const errorResponse = userChangeEmail()[status] || defaultResponse;
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const putStateOfResidence = createAsyncThunk(
  "PUT: /user/{user_id}/state_of_residence",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/user/${payload?.user_id}/state_of_residence`;
    const header = { headers: { Authorization: payload?.token } };
    const config = { ...payload.brandDetails };

    delete payload?.token;
    delete payload?.user_id;
    delete payload?.brandDetails;

    const response = await axios
      .put(url, payload, header)
      .then((res) => {
        const successResponse = putStateOfResidenceResponses(config)[res.status];
        return {
          response: successResponse,
          state: res.data
        };
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = putStateOfResidenceResponses(config)[status]
          || putStateOfResidenceResponses(config)[data.errorMessage]
          || putStateOfResidenceResponses(config)["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const postCreatePassword = createAsyncThunk(
  "POST: /promote/{user_id}",
  async (form, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/promote/${form?.user_id}`;
    const header = { headers: { Authorization: form?.token } };

    delete form?.user_id;
    delete form?.token;

    const response = await axios
      .post(url, form, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = promoteApiResponses()[data.errorMessage]
          || promoteApiResponses()[status]
          || promoteApiResponses()["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const postChangePassword = createAsyncThunk(
  "POST: /user/{user_id}/passwordchange",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/user/${payload?.user_id}/passwordchange`;
    const header = { headers: { Authorization: payload?.token } };

    delete payload?.token;
    delete payload?.user_id;

    const response = await axios
      .post(url, payload, header)
      .then((res) => {
        const successResponse = userChangePassword()[res.status];
        return { ...successResponse, ...res.data };
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const defaultResponse = {
          severity: "error",
          response: removeStatusCode(data.errorMessage)
        };

        const errorResponse = userChangePassword()[status]
          || userChangePassword()[data.errorMessage]
          || defaultResponse;
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const postResetPasswordRequest = createAsyncThunk(
  "POST: /passwordResetRequest",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/passwordresetrequest`;

    const response = await axios
      .post(url, payload)
      .then((res) => {
        return res.data;
      })
      .catch((error) =>
        rejectWithValue({
          status: error.response.status,
          data: error.response.data
        })
      );
    return response;
  }
);

export const postResetPassword = createAsyncThunk(
  "POST: /passwordreset",
  async (form, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/passwordreset`;
    const header = { headers: { Authorization: form?.token } };

    delete form?.token;

    const response = await axios
      .post(url, form, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error?.response?.status,
          data: error?.response?.data
        });
      });
    return response;
  }
);

export const postDeeplinkLogin = createAsyncThunk(
  "POST: /deeplinklogin",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/deeplinklogin`;
    const header = { headers: { Authorization: payload?.token } };
    const config = { ...payload.brandDetails };

    delete payload?.token;
    delete payload?.brandDetails;

    const response = await axios
      .post(url, payload, header)
      .then((res) => res.data)
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = deeplinkLogin(config)[status]
          || deeplinkLogin(config)[data.errorMessage]
          || deeplinkLogin(config)["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const postUniqueIdLookup = createAsyncThunk(
  "POST: /user/uniqueid",
  async (form, { rejectWithValue }) => {
    const response = await axios
      .post(`${REACT_APP_API_URL}/user/uniqueid`, form)
      .then((response) => {
        return response.data;
      })
      .catch(() => {
        const errorResponse = postUniqueIdLookupResponses()["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const getUser = createAsyncThunk(
  "GET: /user/{user_id}",
  async (data, thunkAPI) => {
    const res = await axios
      .get(`${REACT_APP_API_URL}/user/${data.userId}`, {
        headers: { Authorization: data.token }
      })
      .then((response) => {
        return { ...response.data, fromSSO: data.fromSSO };
      })
      .catch((error) => {
        return thunkAPI.rejectWithValue(error?.response?.data);
      });
    return res;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: actions,
  extraReducers: {
    // POST: Token Refresh (User RefreshToken)
    [postTokenRefresh.pending]: (state) => {
      state.authToken.isRefreshing = true;
      state.authToken.apiError = null;
    },
    [postTokenRefresh.fulfilled]: (state, { payload }) => {
      state.authToken.isRefreshing = false;
      state.authToken.apiError = null;
      state.authToken.value = payload.token;
    },
    [postTokenRefresh.rejected]: (state, { payload }) => {
      state.authToken.isRefreshing = false;
      state.authToken.apiError = payload;
      state.authToken.value = "";
    },
    // POST: Login (User Login)
    [postLogin.pending]: (state) => {
      state.login.isSubmitting = true;
      state.login.apiError = null;
      state.timeoutMessage = "";
    },
    [postLogin.fulfilled]: (state, { payload }) => {
      state.login.isSubmitting = false;
      state.form.apiError = null;
      state.form = initialState.form;
      state.user = payload.user;
      state.changeEmail.email.onAuth = payload.user.email_address;
      state.stateOfResidence.hasStateOfResidence = payload.user.state_of_residence ? true : false;
      state.authToken.value = payload.token;
      state.flow = REGISTERED;
    },
    [postLogin.rejected]: (state, { payload }) => {
      state.login.isSubmitting = false;
      state.login.loginError = payload;
      state.login.failures += 1;
      state.failures += 1;
    },
    // POST: Express Pay (User GuestPost)
    [postExpressPayAuth.pending]: (state) => {
      state.guestAuth.isSubmitting = true;
      state.guestAuth.apiError = null;
      state.timeoutMessage = "";
    },
    [postExpressPayAuth.fulfilled]: (state, action) => {
      state.guestAuth.isSubmitting = false;
      state.guestAuth.success = true;
      state.guestAuth.apiError = null;
      state.user = action.payload.user;
      state.authToken.value = action.payload.token;
      state.stateOfResidence.hasStateOfResidence = false;
      state.loans = action.payload.loans;
      state.flow = EXPRESS_PAY;
    },
    [postExpressPayAuth.rejected]: (state, action) => {
      state.guestAuth.isSubmitting = false;
      state.guestAuth.apiError = action.payload;
      state.guestAuth.success = false;
      state.guestAuth.failures += 1;
      state.failures += 1;
    },
    // POST: Register (User GuestPost)
    [postRegister.pending]: (state) => {
      state.register.isSubmitting = true;
      state.register.apiError = null;
      state.register.response = null;
      state.timeoutMessage = "";
    },
    [postRegister.fulfilled]: (state, action) => {
      state.register.isSubmitting = false;
      state.register.apiError = null;
      state.register.response = action.payload;
      state.register.success = true;
      state.authToken.value = action.payload?.token;
      state.stateOfResidence.hasStateOfResidence = action.payload.user.state_of_residence ? true : false;
      state.user = action.payload?.user;
    },
    [postRegister.rejected]: (state, action) => {
      state.register.isSubmitting = false;
      state.register.apiError = action.payload;
      state.register.response = action.payload;
      state.register.success = false;
      state.register.failures += 1;
    },
    // POST: Non Validation Register (User Provisional)
    [postNonValidationRegister.pending]: (state) => {
      state.register.nonValidation.isSubmitting = true;
      state.register.nonValidation.isSuccessful = false;
      state.register.nonValidation.response = false;
      state.register.nonValidation.error = null;
    },
    [postNonValidationRegister.fulfilled]: (state, action) => {
      state.register.nonValidation.isSubmitting = false;
      state.register.nonValidation.isSuccessful = true;
      state.register.nonValidation.response = action.state;
      state.register.nonValidation.error = null;
      state.authToken.value = action.payload?.token;
      state.stateOfResidence.hasStateOfResidence = false;
      state.user = action.payload?.user;
    },
    [postNonValidationRegister.rejected]: (state, action) => {
      state.register.nonValidation.isSubmitting = false;
      state.register.nonValidation.isSuccessful = false;
      state.register.nonValidation.response = null;
      state.register.nonValidation.error = action.payload;
    },
    // POST: Create Password (User Promote)
    [postCreatePassword.pending]: (state) => {
      state.promote.isSubmitting = true;
      state.promote.response = null;
    },
    [postCreatePassword.fulfilled]: (state, action) => {
      state.promote.isSubmitting = false;
      state.promote.response = action.payload;
      state.authToken.value = action.payload?.token;
      state.user = action.payload?.user;
    },
    [postCreatePassword.rejected]: (state, action) => {
      state.promote.isSubmitting = false;
      state.promote.response = action.payload;
    },
    // POST: Unique ID Lookup (User Promote)
    [postUniqueIdLookup.pending]: (state) => {
      state.uniqueId.isSubmitting = true;
      state.uniqueId.response = null;
      state.uniqueId.apiError = null;
    },
    [postUniqueIdLookup.fulfilled]: (state, action) => {
      state.uniqueId.isSubmitting = false;
      state.uniqueId.response = action.payload;
      state.uniqueId.apiError = null;
    },
    [postUniqueIdLookup.rejected]: (state, action) => {
      state.uniqueId.isSubmitting = false;
      state.uniqueId.response = null;
      state.uniqueId.apiError = action.payload;
    },
    // PUT: Change Email (User PutPhone)
    [putChangeEmail.pending]: (state) => {
      state.changeEmail.api.isSubmitting = true;
      state.changeEmail.api.success = false;
      state.changeEmail.api.response = initialState.changeEmail.api.response;
    },
    [putChangeEmail.fulfilled]: (state, { payload }) => {
      state.changeEmail.api.isSubmitting = false;
      state.user.email_address = payload.email_address;
      state.changeEmail.email.current = payload.email_address;
      state.changeEmail.email.changed = true;
      state.changeEmail.api.response = payload;
    },
    [putChangeEmail.rejected]: (state, { payload }) => {
      state.changeEmail.api.isSubmitting = false;
      state.changeEmail.api.response = payload;
      state.changeEmail.api.success = false;
      state.changeEmail.api.failures += 1;
    },
    // PUT: State of Residence (User PutPhone)
    [putStateOfResidence.pending]: (state) => {
      state.stateOfResidence.api.isSubmitting = true;
      state.stateOfResidence.api.success = false;
      state.stateOfResidence.api.response = initialState.stateOfResidence.api.response;
    },
    [putStateOfResidence.fulfilled]: (state, { payload }) => {
      state.stateOfResidence.api.isSubmitting = false;
      state.stateOfResidence.hasStateOfResidence = true;
      state.stateOfResidence.api.response = payload.response;
      state.user.state_of_residence = payload.state.state_of_residence;
      state.stateOfResidence.api.success = true;
    },
    [putStateOfResidence.rejected]: (state, { payload }) => {
      state.stateOfResidence.api.isSubmitting = false;
      state.stateOfResidence.api.response = payload;
      state.stateOfResidence.api.success = false;
      state.stateOfResidence.api.failures += 1;
    },
    // POST: Change Password (User ChangePassword)
    [postChangePassword.pending]: (state) => {
      state.changePassword.api.isSubmitting = true;
      state.changePassword.api.success = false;
      state.changePassword.api.response = initialState.changePassword.api.response;
    },
    [postChangePassword.fulfilled]: (state, { payload }) => {
      state.changePassword.api.isSubmitting = false;
      state.changePassword.api.changed = true;
      state.changePassword.api.response = payload;
    },
    [postChangePassword.rejected]: (state, { payload }) => {
      state.changePassword.api.isSubmitting = false;
      state.changePassword.api.response = payload;
      state.changePassword.api.success = false;
      state.changePassword.api.failures += 1;
    },
    // POST: Reset Password Request (User PasswordResetRequest)
    [postResetPasswordRequest.pending]: (state) => {
      state.resetPasswordRequest.isSubmitting = true;
      state.resetPasswordRequest.response = "";
      state.resetPasswordRequest.apiError = null;
    },
    [postResetPasswordRequest.fulfilled]: (state, { payload }) => {
      state.resetPasswordRequest.isSubmitting = false;
      state.resetPasswordRequest.response = payload;
      state.resetPasswordRequest.apiError = null;
    },
    [postResetPasswordRequest.rejected]: (state, { payload }) => {
      state.resetPasswordRequest.isSubmitting = false;
      state.resetPasswordRequest.response = null;
      state.resetPasswordRequest.apiError = payload;
    },
    // POST: Reset Password (User PasswordReset)
    [postResetPassword.pending]: (state) => {
      state.resetPasswordRequest.isSubmitting = true;
      state.resetPasswordRequest.response = "";
      state.resetPasswordRequest.apiError = null;
    },
    [postResetPassword.fulfilled]: (state, { payload }) => {
      state.resetPasswordRequest.isSubmitting = false;
      state.resetPasswordRequest.response = payload;
      state.resetPasswordRequest.apiError = null;
    },
    [postResetPassword.rejected]: (state, { payload }) => {
      state.resetPasswordRequest.isSubmitting = false;
      state.resetPasswordRequest.response = null;
      state.resetPasswordRequest.apiError = payload;
    },
    // POST: Deeplink Password (Deeplink Login)
    [postDeeplinkLogin.pending]: (state) => {
      state.deeplink.api.isSubmitting = true;
      state.deeplink.api.success = false;
      state.deeplink.api.response = initialState.deeplink.api.response;
    },
    [postDeeplinkLogin.fulfilled]: (state, { payload }) => {
      state.deeplink.api.isSubmitting = false;
      state.deeplink.api.response = payload;
      state.user = payload.user;
      state.authToken.value = payload.token;
      state.flow = roleTypes[payload.user.role];
      state.deeplink.api.success = true;
      state.stateOfResidence.hasStateOfResidence = payload.user.state_of_residence ? true : false;
    },
    [postDeeplinkLogin.rejected]: (state, { payload }) => {
      state.deeplink.api.isSubmitting = false;
      state.deeplink.api.response = payload;
      state.deeplink.api.success = false;
      state.deeplink.api.failures += 1;
    },
    // GET: User (User Get)
    [getUser.pending]: (state) => {
      state.getUser.isSubmitting = true;
    },
    [getUser.fulfilled]: (state, { payload }) => {
      const [result] = payload.results;
      state.user = { ...result, from_sso: payload.fromSSO };
      state.flow = roleTypes[state.user.role];
      state.getUser.isSubmitting = false;
    },
    [getUser.rejected]: (state, action) => {
      state.getUser.apiError = action?.payload || action;
    }
  }
});

export const {
  setEntryPoint,
  setShouldClearBreadcrumbs,
  setAuthToken,
  deleteAuthToken,
  setTimeoutMessage,
  setEmail,
  setFirstName,
  setLastName,
  setMemberAccount,
  setFirstSecurityQuestion,
  setSecondSecurityQuestion,
  setForm,
  setNonValidationPayload,
  resetAuthToken,
  resetForm,
  resetAuth,
  resetUniqueId,
  resetProfileApiResponses,
  clearStateOfResidenceResponse,
  setFlow,
  clearAuthStateOnLogout
} = authSlice.actions;

export default authSlice.reducer;
