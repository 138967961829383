import React, { useEffect, useState } from "react";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import { getNonBankingDays } from "../../../../store/nonBankingDays/nonBankingDays.slice";
import { swivelColors } from "../../../../utils/data/swivelColors";
import { addBusinessDays, getFirstBusinessDay, validateIsBusinessDay } from "../../../../utils/helpers/dayjsHelpers";
import { useViewPort } from "../../../../utils/hooks/useViewPort";


const DatePicker = ({
  additionalProps,
  onChange,
  error: formError,
  value,
  config,
  maxPostDays,
  startDate = undefined,
  setValidDates,
  validDates,
  disableWeekendsOnly = false,
  disabled = false,
  label = "Date",
  frequency = undefined
}) => {
  // * Named selectors
  const authState = (state) => state.auth;
  const institutionState = (state) => state.institution;
  const nonBankingDaysState = (state) => state.nonBankingDays;

  // * Redux state
  const { authToken } = useSelector(authState);
  const { details } = useSelector(institutionState);
  const { isLoading } = useSelector(nonBankingDaysState);

  // * Hooks
  const [nonBankingDays, setNonBankingDays] = useState([]);
  const viewPort = useViewPort();
  const dispatch = useDispatch();
  const { swivelize } = useFlags();

  // * Watchers
  useEffect(() => {
    dispatch(getNonBankingDays(authToken.value))
      .unwrap()
      .then(res => {
        const nonBankingDays = getDisabledDates(res.value);
        setNonBankingDays(nonBankingDays);

        // * this function accounts for max posts days, banking holidays, and weekends
        setValidDates(getValidDates(nonBankingDays));
      }).
      catch(() => {});
  }, []);

  const getDisabledDates = (nonBankingDays) => {
    const years = Object.keys(nonBankingDays);
    const datesToDisable = years.reduce((arr, year) => {

      const datesInTheYear = nonBankingDays[year].reduce((arr, { date }) => {
        if (date.length !== 4) return arr;

        date = [...date];
        date.splice(2, 0, " ");
        const formattedDay = dayjs(date.join("")).format(`MM/DD[/${year}]`);
        arr.push(formattedDay);
        return arr;
      }, []);

      return [...arr, ...datesInTheYear];
    }, []);

    return datesToDisable;
  };


  const getValidDates = (nonBankingDays) => {
    const validDates = [];
    const firstBusinessDay = getFirstBusinessDay(details?.daily_cutoff_time);
    const isTodayBankingDay = dayjs(firstBusinessDay).isSame(dayjs().startOf("day"));

    if (isTodayBankingDay) validDates.push(dayjs(firstBusinessDay).format("MM/DD/YYYY"));

    const amountOfDatesToAdd = isTodayBankingDay ? maxPostDays - 1 : maxPostDays;
    let businessDayCounter = validDates.length;
    while (validDates.length <= amountOfDatesToAdd) {
      const nextBusinessDay = addBusinessDays(businessDayCounter++).format("MM/DD/YYYY");
      const isBankingHoliday = nonBankingDays?.includes(nextBusinessDay);
      if (!isBankingHoliday) validDates.push(nextBusinessDay);
    }
    return validDates;
  };

  const decideIfDateShouldBeDisabled = (dateInQuestion) => {
    const formattedDate = dayjs(dateInQuestion).format("MM/DD/YYYY");

    if (disableWeekendsOnly) {
      return !validateIsBusinessDay(dateInQuestion) || nonBankingDays.includes(formattedDate);
    }
    return !validDates.includes(formattedDate);
  };

  const getMaxDate = () => {
    if (disableWeekendsOnly) return undefined;
    if (!maxPostDays) return getDefaultMaxPostDay();
    return validDates[validDates.length-1];
  };

  const getDefaultMaxPostDay = () => {
    // * last day of next month
    const daysInNextMonth = dayjs().add(1, "month").daysInMonth();
    const daysToAdd = daysInNextMonth - dayjs().date();
    return dayjs().add(1, "month").add(daysToAdd, "day");
  };

  const datePickerProps = {
    allowKeyboardControl: true,
    components: {
      OpenPickerIcon: CalendarMonthIcon,
      LeftArrowIcon: ArrowLeftIcon,
      RightArrowIcon: ArrowRightIcon
    },
    disablePast: true,
    inputFormat: "MM/DD/YYYY",
    inputProps: { "aria-required": true, "data-cy": "datepicker-textBox" },
    InputProps: { sx: { "& .MuiSvgIcon-root": { color: swivelize ? swivelColors.primary_button_color : config?.primary_button_color } } },
    label,
    loading: isLoading,
    maxDate: getMaxDate(),
    minDate: startDate || getFirstBusinessDay(details?.daily_cutoff_time),
    onChange: (event) => { onChange(event); },
    OpenPickerButtonProps: { "aria-label": "Click to open date picker", "data-cy": "datepicker-button" },
    PopperProps: { sx: { "& .MuiPaper-root": {
      border: `2px solid ${swivelize ? swivelColors.primary_button_color : config?.primary_button_color}`,
      padding: 1
    } } },
    shouldDisableDate: (chosenDate) => ( frequency === "Monthly" ? false : decideIfDateShouldBeDisabled(chosenDate) ),
    showDaysOutsideCurrentMonth: false,
    value,
    ...additionalProps
  };

  const textBoxProps = {
    error: !!formError,
    helperText: formError?.message || "MM/DD/YYYY",
    fullWidth: true,
    variant: "outlined"
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {
        viewPort.medium
          ?
          (
            <DesktopDatePicker data-cy="datepicker"
              {...datePickerProps}
              disabled={disabled}
              renderInput={(props) => (
                <TextField
                  {...props}
                  {...textBoxProps}
                  sx={{ zIndex: 0 }}
                />
              )}
            />

          )
          :
          (
            <MobileDatePicker data-cy="datepicker"
              {...datePickerProps}
              disabled={disabled}
              renderInput={(props) => (
                <TextField
                  {...props}
                  {...textBoxProps}
                  sx={{ zIndex: 0 }}
                />
              )}
            />
          )
      }
    </LocalizationProvider>
  );

};

export default DatePicker;
