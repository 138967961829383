import React from "react";
import { FormControlLabel, FormHelperText, Link, Checkbox, FormControl } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Grid, Typography, Button } from "../../../components";
import Title from "../../../components/Title";
import { setNonValidationPayload } from "../../../store/auth/auth.slice";
import { useMultipleFormErrors } from "../../../utils/hooks/useMultipleFormErrors";
import { schema } from "../../../utils/validators/formHookSchema";
import AccountDetails from "../components/NoVaidationAccountDetails";
import UserInfo from "../components/UserInfo";

const NonValidation = () => {
  // Named Selectors
  const institutionState = (state) => state.institution;
  const authState = (state) => state.auth;

  // Redux State
  const { config } = useSelector(institutionState);
  const { form: reduxForm, register: { nonValidation } } = useSelector(authState);

  const uniqueIdLookupEnabled = config?.services?.unique_id_lookup?.registered?.enabled;

  // Hooks
  const getDefaultValues = () => {
    const staticValues = {
      firstName: nonValidation?.payload?.first_name || "",
      lastName: nonValidation?.payload?.last_name || "",
      emailAddress: nonValidation?.payload?.email_address || reduxForm?.emailAddress || "",
      memberId: nonValidation?.payload?.member_id || ""
    };

    config?.no_validation_file?.registration_fields?.forEach(field => {
      const data = nonValidation?.payload?.registration_fields?.find(data => data.label === field.label);
      staticValues[field.identifier] = data?.value || "";
    });

    return staticValues;
  };

  const { register, handleSubmit, formState: { errors: formErrors }, control } = useForm({
    mode: "onBlur",
    defaultValues: getDefaultValues()
  });
  const { errorMessage } = useMultipleFormErrors(formErrors);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const executePostRegistration = (form) => {
    const registrationFields = config?.no_validation_file?.registration_fields?.reduce((result, field) => {
      const data = {
        label: field.label,
        secure: field.secure,
        value: form[field.identifier]
      };
      result.push(data);
      return result;
    }, []);

    const payload = {
      email_address: form.emailAddress,
      first_name: form.firstName,
      institution_id: config.id,
      last_name: form.lastName,
      member_id: form.memberId,
      password: "",
      registration_fields: registrationFields
    };

    dispatch(setNonValidationPayload(payload));
    navigate("/non-validation/create-password");
  };

  // Config Checks
  if (typeof uniqueIdLookupEnabled !== "boolean") {
    console.warn("Missing enabled value for unique_id_lookup registered path.");
  }

  return (
    <>
      <Title title="Register" subTitle={config?.instructions?.registration?.get_started || ""} />
      <Alert message={errorMessage} severity="error" />
      <form onSubmit={handleSubmit(executePostRegistration)} noValidate={true}>
        <Grid
          container
          spacing={3}
          component="fieldset"
          sx={{ border: "none", padding: "0" }}
        >
          <Grid item xs={12}>
            <Box>
              <Typography component="p" variant="body2" noWrap>* Required Field </Typography>
            </Box>
          </Grid>

          <UserInfo
            formErrors={formErrors}
            register={register}
            reduxForm={reduxForm}
            dispatch={dispatch}
            uniqueIdLookupEnabled={uniqueIdLookupEnabled}
          />

          <AccountDetails
            formErrors={formErrors}
            register={register}
            config={config}
            uniqueIdLookupEnabled={uniqueIdLookupEnabled}
          />

          <Grid item xs={12}>
            <FormControl component="fieldset" error={!!formErrors?.confirmation}>
              <Controller
                name="confirmation"
                control={control}
                defaultValue={false}
                rules={schema.checkBox.properties}
                render={({
                  field: props
                }) => {
                  return (
                    <FormControlLabel
                      label={
                        <Box>
                          <Typography component="span">
                            {"By clicking this box you are submitting e-sign consent and agreeing to the terms and conditions stated in the "}
                          </Typography>
                          <Link target="_blank" rel="noopener" href={config?.terms_and_conditions_url}>
                              Terms of Service
                          </Link>
                        </Box>
                      }
                      control={
                        <Checkbox {...props} inputProps={{ "aria-required": "true" }} />
                      }
                    />
                  );
                }}
              />
              <FormHelperText>{formErrors?.confirmation?.message || " "}</FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} container direction="row-reverse">
            <Grid item xs={12} md={6}>
              <Button
                color="primary"
                type="submit"
                disabled={nonValidation.isSubmitting}
                loading={nonValidation?.isSubmitting}
              >
                  Create Password
              </Button>
            </Grid>
          </Grid>

        </Grid>
      </form>
    </>
  );
};

export default NonValidation;
