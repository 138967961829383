import React, { useState } from "react";
import { Lock, LockOpen } from "@mui/icons-material";
import {
  Button,
  FormControl,
  FormControlLabel
} from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Alert, Box, Typography, Grid, Checkbox } from "../../components/mui";
import { StyledCircularProgress } from "../../components/styled/StyledCircularProgress";
import { StyledDivider } from "../../components/styled/StyledDivider";
import Title from "../../components/Title";
import { useGoogleAnalytics } from "../../services/analytics/hooks/useGoogleAnalytics";
import { putEditAutopay } from "../../store/loans/loans.slice";
import { AGREE_TERMS } from "../../utils/constants/constants";
import { getAutopayConvenienceFee } from "../../utils/helpers/getPaymentConfig";
import { getAccountLastFour } from "../../utils/helpers/handleAccounts";

const AuthorizeAutopay = () => {
  // Named selectors
  const paymentState = (state) => state.payment;
  const institutionState = (state) => state.institution;
  const savedAccountsState = (state) => state.savedAccounts;
  const authState = (state) => state.auth;
  const loanState = (state) => state.loans;

  // Pass in named selectors and gets state from redux
  const { config, details } = useSelector(institutionState);
  const { autopay } = useSelector(loanState);
  const { loan } = useSelector(paymentState);
  const { autopay: { payFromDetails } } = useSelector(paymentState);
  const { getAccounts } = useSelector(savedAccountsState);
  const { authToken } = useSelector(authState);

  // Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trackEvent, actions, categories } = useGoogleAnalytics();

  // Local State
  const [checked, setChecked] = useState(false);
  const [errorText, setText] = useState("");

  const convenienceFee = Math.abs(getAutopayConvenienceFee(details, { loan: loan?.type }));
  const displayTodaysDate = dayjs().format("MM/DD/YYYY");
  const boxStyle = { display: "flex", justifyContent: "space-between", margin: "2px 0" };
  const savedAccount = getAccounts?.response?.results?.find(account => account.id === payFromDetails.account_id);
  const accountLastFour = getAccountLastFour(loan?.number);

  // Handlers
  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setText("");
    }
  };

  const handleClickSubmit = () => {
    if (checked) {
      const payload = {
        token: authToken.value,
        account_id: payFromDetails?.account_id,
        loan_id: loan?.id,
        ending: payFromDetails?.ending.toLowerCase()?.replaceAll(" ", "_"),
        frequency: payFromDetails?.frequency?.toLowerCase()?.replaceAll(" ", "_"),
        payment_amount: Number(payFromDetails?.payment_amount),
        process_after: payFromDetails?.process_after,
        skips: [],
        starting_date: dayjs(payFromDetails?.starting_date).format("YYYY-MM-DD"),
        brandDetails: {
          brand_phone: config?.brand_phone,
          brand_name: config?.brand_name
        }
      };

      if (payFromDetails?.ending === "on_a_specific_date") {
        payload.ending_date = dayjs(payFromDetails?.endDate).format("YYYY-MM-DD");
      }

      dispatch(putEditAutopay(payload))
        .unwrap()
        .then(() => {
          trackEvent(actions.AUTHORIZE_AUTOPAY, categories.ATTEMPT_SUCCESS);
          navigate("/accounts", { state: { autoPayMessage: "Autopay schedule successfully created!" } });
        })
        .catch(() => {
          trackEvent(actions.AUTHORIZE_AUTOPAY, categories.ATTEMPT_FAILURE);
        });
    }
    else {
      setText(AGREE_TERMS);
    }
  };

  const calculateTotal = () => {
    return (Number(payFromDetails.payment_amount) + Number(convenienceFee));
  };

  // Formatters
  const displayAutopayFrequency = {
    "monthly": "monthly",
    "every_two_weeks": "bi-weekly",
    "weekly": "weekly"
  };

  const frequencyVerbiage = displayAutopayFrequency[payFromDetails?.frequency] === "every_two_weeks"
    ? `an ${displayAutopayFrequency[payFromDetails?.frequency]}`
    : `a ${displayAutopayFrequency[payFromDetails?.frequency]}`;

  return (
    <>
      <Title title="Autopay Schedule" />
      <Alert message={errorText} severity="error" />
      <Alert
        message={autopay?.api?.response?.response}
        severity={autopay?.api?.response?.severity}
      />
      <Grid item xs={12}>
        <Typography variant="body1" component="p">
          Today&apos;s date is: {displayTodaysDate}
        </Typography>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={boxStyle}>
            <Typography variant="body1">Frequency:</Typography>
            <Typography variant="body1" sx={{ textTransform: "capitalize" }}>{displayAutopayFrequency[payFromDetails?.frequency]}</Typography>
          </Box>
          <Box sx={boxStyle}>
            <Typography variant="body1">Start Date:</Typography>
            <Typography variant="body1">{payFromDetails?.starting_date}</Typography>
          </Box>
          { }
          <Box sx={boxStyle}>
            <Typography variant="body1">End Date:</Typography>
            <Typography variant="body1">
              {payFromDetails?.ending === "on_a_specific_date" ? payFromDetails?.endDate : "Until you cancel"}
            </Typography>
          </Box>
          <Box sx={boxStyle}>
            <Typography variant="body1">Paid To:</Typography>
            <Typography variant="body1"> Loan ending in {accountLastFour}</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={boxStyle}>
            <Typography variant="body1">Pay Using:</Typography>
            <Typography variant="body1">Account ending in {savedAccount?.last_4}</Typography>
          </Box>
          <Box sx={boxStyle}>
            <Typography variant="body1">Routing Number:</Typography>
            <Typography variant="body1">{savedAccount?.banking_details?.routing_number}</Typography>
          </Box>
          <Box sx={boxStyle}>
            <Typography variant="body1">Bill Amount:</Typography>
            <Typography variant="body1">${Number(payFromDetails.payment_amount).toFixed(2)}</Typography>
          </Box>
          <Box sx={boxStyle}>
            <Typography variant="body1">Fees:</Typography>
            <Typography variant="body1"> ${Number(convenienceFee).toFixed(2)}</Typography>
          </Box>
        </Grid>
      </Grid>
      <StyledDivider />
      <Grid container spacing={3} justifyContent="flex-end">
        <Grid item xs={12} md={6}>
          <Box sx={boxStyle}>
            <Typography variant="body1">Total:</Typography>
            <Typography variant="body1">$ {Number(calculateTotal()).toFixed(2)}</Typography>
          </Box>
        </Grid>
      </Grid>
      <StyledDivider />
      <Grid container gap={".85rem"}>
        <Typography variant="body1" component="p">
          You are authorizing {details.brand_name} to initiate {frequencyVerbiage} recurring transaction from
          your account ending in {savedAccount?.last_4} and routing number {savedAccount?.banking_details?.routing_number} in
          the amount of ${calculateTotal()},
          with a start date on or after {payFromDetails?.starting_date},
          {payFromDetails?.ending === "on_a_specific_date" ? ` with an end date of ${payFromDetails?.endDate}, ` : " "}
          which includes a Convenience Fee of ${Number(convenienceFee).toFixed(2)}.
        </Typography>
        <Typography variant="body1" component="p">
          This amount excluding the convenience fee, will be applied to your {details.brand_name} account ending in {accountLastFour}.
        </Typography>
        <Typography variant="body1" component="p">
          This authorization may be revoked, prior to the processing of this transaction or future transactions
          by contacting {details.brand_name} at {details.brand_phone}.
        </Typography>
        <FormControl error fullWidth sx={{ textAlign: "center" }}>
          <Box>
            <FormControlLabel
              aria-label="Click check mark box to agree to payment terms."
              control={<Checkbox checked={checked} onChange={handleChange} />}
              label="I agree to the above Payment Terms"
              sx={{ marginRight: "0.3rem" }} />
          </Box>
        </FormControl>
        <StyledDivider />
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: "0.25rem" }} justifyContent="flex-end">
        <Grid item xs={12} md={6} order={{ sm: 1, md: 2 }}>
          <Box sx={{ position: "relative" }} gridArea="button">
            <Button
              startIcon={checked ? <LockOpen /> : <Lock />}
              color="primary"
              disabled={autopay?.api?.isSubmitting}
              onClick={handleClickSubmit}
              fullWidth
              size="large"
              variant="contained">
              Authorize AutoPay Schedule
            </Button>
            {autopay?.api?.isSubmitting && <StyledCircularProgress />}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default AuthorizeAutopay;
