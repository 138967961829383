import creditCardType from "credit-card-type";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import luhn from "fast-luhn";
import {
  setCardNumbers,
  setConvFee,
  setTotal,
  setPaymentAmount,
  setCardBrand,
  setSecurityCode,
  setExpirationDate,
  setZipCode
} from "../../store/payment/makePayment.slice";

dayjs.extend(isSameOrAfter);
dayjs.extend(customParseFormat);

export const sanitizeCardNumber = (ccNumber) => ccNumber.replace(/ /g, "");

export const setAmountOnLoad = (loan, form, convFee, dispatch) => {
  const amountDue = Number(form.amount) || Number(loan?.next_amount_due) || 0;
  const total = Number(amountDue) + Number(convFee);

  dispatch(setPaymentAmount(amountDue.toFixed(2)));
  dispatch(setConvFee(convFee));
  dispatch(setTotal(total));
};

export const handleExpirationDate = (prevValue, currentValue, setError, clearErrors, dispatch) => {
  const regex = new RegExp(/([\d/])/g);
  const isInvalidCharacters = currentValue.replace(regex, "");
  if (isInvalidCharacters) {
    setError("expirationDate", { type: "custom", message: "Expiration date should be in MM/YY format" });
    return;
  }
  clearErrors("expirationDate");
  dispatch(setExpirationDate(formatExpDate(prevValue, currentValue)));
};

export const validateExpirationDate = (value) => {
  const inputConverted = dayjs(value, "MM/YY", true);
  const isValidFormat = inputConverted.isValid();
  const errorMessage = "Invalid expiry date";
  if (!isValidFormat) { return errorMessage; }
  if (value.length !== 5) { return errorMessage; }

  const today = new Date();
  const currentExpirationDate = dayjs(today);
  const isValid = inputConverted.isSameOrAfter(currentExpirationDate, "month");
  if (!isValid) { return errorMessage; }
  return null;
};

export const validateCvv = (value, cardBrand) => {
  const isNumeric = /[0-9]/g.test(value);
  if (!isNumeric) { return "CVV must be numeric"; }
  const brand = cardBrand?.toLowerCase() || "";
  const isAmex = brand === "amex" || brand === "american-express";
  if (isAmex && value.length !== 4) { return "4 Digit CVV is required"; }
  if (!isAmex && value.length !== 3) { return "3 Digit CVV is required"; }
  return null;
};

export const validateCardNumber = (payload) => {
  const {
    cardNumber,
    acceptedCardTypes,
    cardBrand,
    nativeBinList,
    nativeBinErrorMessage
  } = payload;

  const errorMessages = {
    cardBin: nativeBinErrorMessage,
    cardList: `Accepted card types are ${acceptedCardTypes?.join(", ")}`,
    cardNumber: "Invalid card number"
  };

  // Helper Methods
  const replaceAndLowercase = (ele, regex, tree) => {
    return ele.replace(regex, tree).toLowerCase();
  };

  const matchingCards = (brand) => {
    const brandValue = replaceAndLowercase(brand, /[^\w]/g, "");
    const cardValue = replaceAndLowercase(cardBrand, /[^\w]/g, "");
    return brandValue === cardValue;
  };

  // Variables
  const sanitizeCard = sanitizeCardNumber(cardNumber);
  const cardBin = sanitizeCard.substring(0, 6);

  const filteredCardTypes = acceptedCardTypes?.filter(matchingCards);
  const hasInvalidCardTypes = !filteredCardTypes?.length;

  const isNativeBinAnArray = Array.isArray(nativeBinList);
  const hasBlackistedNativeBin = !!nativeBinList.includes(cardBin);
  const hasBlacklistedCard = isNativeBinAnArray && hasBlackistedNativeBin;

  const isValidCardNumber = luhn(sanitizeCard);
  const hasInvalidCard = !isValidCardNumber;

  // Conditionals
  if (hasInvalidCard) {
    return errorMessages.cardNumber;
  } else if (hasBlacklistedCard) {
    return errorMessages.cardBin;
  } else if (hasInvalidCardTypes) {
    return errorMessages.cardList;
  }
};

export const handleCardNumber = (value, cvv, setMaxCvvLength, setError, clearErrors, dispatch) => {
  const cardNumber = sanitizeCardNumber(value);
  if (isNaN(cardNumber)) {
    setError("cardNumber", { type: "custom", message: "Card number can only be numeric" });
    dispatch(setSecurityCode(""));
    return;
  }
  clearErrors("cardNumber");
  const cardInfo = getCardInfo(cardNumber);
  if (!cardInfo || !value) {
    resetBrandAndSecurityCode(value, setMaxCvvLength, dispatch);
    return;
  }
  const cardBrand = (cardInfo?.brand || "").toLowerCase();
  const isAmex = cardBrand === "amex" || cardBrand === "american-express";
  const cvvLength = !isAmex ? 3 : 4;
  const currentCvvLength = cvv?.length || 0;
  if (currentCvvLength !== 0 && currentCvvLength !== cvvLength) {
    setError("securityCode", { type: "custom", message: `Please update the security code. Length required: ${cvvLength}` });
  }

  setMaxCvvLength(cvvLength);
  dispatch(setCardBrand(cardBrand));
  dispatch(setCardNumbers({ raw: cardInfo.rawCardNumber, userDisplay: cardInfo.userDisplay }));
};

export const handleSecurityCode = (value, setError, clearErrors, dispatch) => {
  if (isNaN(value)) {
    setError("securityCode", { type: "custom", message: "Security code must be numeric" });
    return;
  }

  clearErrors("securityCode");
  dispatch(setSecurityCode(value));
};

const resetBrandAndSecurityCode = (value, setMaxCvvLength, dispatch) => {
  dispatch(setSecurityCode(""));
  dispatch(setCardBrand(""));
  setMaxCvvLength(3);
  dispatch(setCardNumbers({ raw: value, userDisplay: value }));
};

export const setAmountAndTotal = (amount, convFee, dispatch) => {
  const total = getTotal(amount, convFee);
  dispatch(setPaymentAmount(amount));
  dispatch(setTotal(total));
};

export const getTotal = (amount, convFee) => {
  if (isNaN(amount)) { return Number(convFee); }
  return Number(amount) + Number(convFee);
};

export const validateAmount = (value, maxAmount, minAmount) => {
  const isValidFormat = /^[.,\d]+$/.test(value);
  if (!isValidFormat) { return "Please enter a valid payment amount"; }

  const valueWithOutSpecialChars = value.replace(/[^.\d]/g, "");
  const isGreaterThanMax = Number(valueWithOutSpecialChars) > maxAmount;
  const isLessThanMin = Number(valueWithOutSpecialChars).toFixed() < minAmount;

  if (isGreaterThanMax) { return `Payment amount cannot be greater than $${maxAmount}`; }
  if (isLessThanMin) { return `Payment amount must be greater than $${minAmount}`; }

  return null;
};

export const formatAmount = (value, convFee, dispatch, setValue) => {
  value = Number(value).toFixed(2);
  setAmountAndTotal(value, convFee, dispatch);
  setValue("amount", value);
};

export const handlePaymentAmount = (currentValue, previousValue, convFee, setError, clearErrors, dispatch, setValue) => {
  if (previousValue && !currentValue) {
    dispatch(setPaymentAmount(currentValue));
    previousValue.current = currentValue;
    setValue("amount", currentValue);
    return;
  }

  const isStartOfDecimal = currentValue === ".";
  if (!isStartOfDecimal && isNaN(currentValue)) {
    setError("amount", { type: "custom", message: "Amount must be numeric and greater than $1.00" });
    setValue("amount", previousValue.current);
    return;
  }

  if (isStartOfDecimal || Number(currentValue) < 1) {
    setError("amount", { type: "custom", message: "Please enter an amount greater than $1.00" });
    setValue("amount", previousValue.current);
    return;
  }

  clearErrors("amount");
  setAmountAndTotal(currentValue, convFee, dispatch);
  previousValue.current = currentValue;
  setValue("amount", currentValue);
};

export const setStateDefaultValue = (setValue, formValue, statesList) => {
  if (!formValue) return "";

  const savedStateData = statesList.find( (s) => s.longName.toLowerCase() === formValue.toLowerCase() );
  setValue("state", savedStateData.longName, { shouldDirty: true });
  return savedStateData;
};

export const handleZipCode = (value, setError, clearErrors, dispatch) => {
  const regex = new RegExp(/[\d]/g);
  const isInvalidCharacters = value.replace(regex, "");
  if (isInvalidCharacters) {
    setError("zipCode", { type: "custom", message: "Zip code should be 5 numeric digits" });
    return;
  }
  clearErrors("zipCode");
  dispatch(setZipCode(value));
};

export const formatExpDate = (prevValue, currentValue) => {
  if (!currentValue) return currentValue;

  const prevLength = prevValue?.length || 0;
  const currentLength = currentValue?.length || 0;
  // Backspace previous: 03/ current: 03 then: 03
  if (prevLength === 3 && currentLength === 2) {
    return currentValue;
  }
  // previous: 0 current: 03 then: 03/
  if (prevLength === 1 && currentLength === 2) {
    const result = (currentValue += "/");
    return result;
  }
  // previous: 03 current: 03/ then: 03/
  // previous: 03 current: 032 then: 03/2
  if (
    prevLength === 2 &&
    currentLength === 3 &&
    currentValue.charAt(2) !== "/"
  ) {
    const result = currentValue.split("");
    result.splice(2, 0, "/");
    return result.join("");
  }
  // has 3 or more characters and doesn't yet have a / Then: add the / at the second position
  if (
    currentLength >= 3 &&
    currentValue.indexOf("/") > -1 &&
    currentValue.charAt(2) !== "/"
  ) {
    const result = currentValue.replaceAll("/", "").split("");
    result.splice(2, 0, "/");
    return result.join("");
  }
  // When the slash is in the wrong spot. current: 0/ Then: 0
  if (currentLength === 2 && currentValue.indexOf("/") > -1) {
    const result = currentValue.replaceAll("/", "");
    return result;
  }
  // The value has multiple slashes in it
  const matches = currentValue.match(/\//g);
  if (matches && matches.length >= 2) {
    let result = currentValue.replaceAll("/", "");
    if (result.length < 2) return result;
    if (result.length === 2) return (result += "/");
    if (result.length >= 3) {
      const finalResult = result.split("");
      finalResult.splice(2, 0, "/");
      return finalResult.join("");
    }
  }

  return currentValue;
};

export const getCardInfo = (ccNumber) => {
  const res = { brand: "", rawCardNumber: ccNumber, userDisplay: ccNumber };
  const cards = creditCardType(ccNumber);
  const cardInfo = cards.length > 0 ? cards[0] : {};
  if (!cardInfo?.type) return "";

  const display = [...ccNumber];
  cardInfo.gaps.forEach((gap, i) => {
    if (ccNumber.length > gap) {
      const target = (gap += i);
      display.splice(target, 0, " ");
    }
  });

  res.userDisplay = display.join("");
  res.brand = cardInfo.type;
  return res;
};
